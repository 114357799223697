.footer {
	background: rgb(34,34,34);
	background: -moz-linear-gradient(top,  rgba(34,34,34,1) 0%, rgba(12,12,12,1) 100%);
	background: -webkit-linear-gradient(top,  rgba(34,34,34,1) 0%,rgba(12,12,12,1) 100%);
	background: linear-gradient(to bottom,  rgba(34,34,34,1) 0%,rgba(12,12,12,1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#222222', endColorstr='#0c0c0c',GradientType=0 );
	color: @whiteColor;
	font-size: 15px;
	padding-top: 20px;
	&__footer {
		border-top: 1px solid #3a3a3a;
		margin-top: 20px;
		padding-top: 20px;
		padding-bottom: 20px;
		font-size: 15px;
	}
	a {
		color: @whiteColor;
	}
}