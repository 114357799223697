//
// Bojovnik s whiteSpace
//---------------------------------------------------
.editor-row-persons-content {
    font-size: 0px;
    > * {
        font-size: 16px;
    }
}


//
// Body, html
//---------------------------------------------------
body, html {
    font-family: "open-sans",sans-serif;
    font-size: 16px;
}

//
// Typography
//---------------------------------------------------
h1, h2, h3, h4 {
    color: @themeColor1;
    font-weight: 800;
}

h1 {

}

h2 {
    margin-bottom: 15px;
}

h3 {
    font-size: 25px;
    margin-top: 30px;
    margin-bottom: 20px;
}

p {
    font-size: 15px;
    margin-bottom: 20px;
}

ul {
    > li {

    }     
}

a {
    &:hover, &:focus, &:active {
        
    }
}

//
// Essential classes
//---------------------------------------------------

.img-shadow {   
    -webkit-box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.4);
    -moz-box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.4);
    box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.4);   
    margin-bottom: 20px;
}

.page-header {
    border-color: #bebebe;
}

.no-padding {
    padding: 0;
}

#products {
    margin-top: 40px;
    padding: 0;
}
#products ul.affix {
    position: static;
}
// Large devices and Medium device
@media (min-width: @screen-sm-min) {
    #products ul.affix {
        position: fixed;
        top: 123px;
    }
    #products ul.affix-bottom {
        position: absolute;
    }
}

.img-responsive {
    display: inline-block;
}

.vcenter {
    display: inline-block;
    vertical-align: middle;
    float: none;
}

.no-scroll {
    margin: 0;
    width: 100%;
    height: 100%; 
    position: fixed;
    overflow-y:scroll;
}

.no-scroll-no-sb {
    margin: 0;
    width: 100%;
    height: 100%; 
    overflow-y:hidden;
}

.affix {
    top: 0;
    &, &-top, &-bottom {
    width: 100%;
    z-index: 999;
    }
}

//Extra small devices
@media (max-width: @screen-xs-max) { 
    .display-xs-flex {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
    .text-xs-center {
        text-align: center;
    }
}

// Small devices
@media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) { 
    .display-sm-flex {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
    .text-sm-center {
        text-align: center;
    }
}

@media (min-width: @screen-md-min) { 
    .display-md-flex {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
    .text-md-center {
        text-align: center;
    }
}

@media (min-width: @screen-lg-min) {
    .text-lg-left {
        text-align: left;
    }
}

.m-0-a {
    margin: 0 auto;
}

.shadow-inset {
    box-shadow:
        inset 0px 0px 5px 0px rgba(0, 0, 0, 0.4);
}

.shadow-inset-t {
    box-shadow:
        inset 0px 10px 5px -10px rgba(0, 0, 0, 0.4);
}

.shadow-inset-b {
    box-shadow:
        inset 0px -10px 5px -10px rgba(0, 0, 0, 0.4);
}

.shadow-inset-t-b {
    box-shadow:
        inset 0px 10px 5px -10px rgba(0, 0, 0, 0.4),
        inset 0px -10px 5px -10px rgba(0, 0, 0, 0.4); 
}

//
// Screen resolutions
//---------------------------------------------------
    // Extra small devices
    @media (max-width: @screen-xs-max) { 
    }

    // Small devices
    @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
    }

    // Medium devices
    @media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
    }

    // Large devices
    @media (min-width: @screen-lg-min) {
    }

    // Small devices and Extra small devices
    @media (max-width: @screen-sm-max) {
    }

    // Large devices and Medium device
    @media (min-width: @screen-md-min) {
    }

    // Medium devices and Small devices and Extra small devices
    @media (max-width: @screen-md-max) {
    }

// Extra small devices
@media (max-width: @screen-xs-max) { 
    .section--slider {
        display: none;
    }
}
// Small devices
@media (min-width: @screen-sm-min) {
    //
    // Owl carousel fix
    //---------------------------------------------------
        .owl-carousel {
            z-index: 1;
        }

        .owl-wrapper-outer {
            z-index: 1;
        }

        .owl-stage {
            z-index: 1;
        }

        .owl-stage-outer {
            z-index: 2;
        }

        .owl-item {
            z-index: 1;
        }

    //== Slider
    .wrapper-slider {
      position: relative;
    }
    .slide {
      background-image: url(../img/1920x600.png);
      background-position: center;
      height: 600px;
      text-align: center;
    -webkit-box-shadow: inset 0px 0px 10px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: inset 0px 0px 10px 0px rgba(0,0,0,0.75);
    box-shadow: inset 0px 0px 10px 0px rgba(0,0,0,0.75);
      h1 {
        font-size: 65px;
        color: #fff;
        border: 0;
      }
      p {
        font-size: 20px;
        color: #fff;
        margin-bottom: 30px;
        max-width: 680px;
        margin-left: auto;
        margin-right: auto;
      }
    }
    .owl-nav {
      position: absolute;
      z-index: 2;
      top: 237px;
      left: 36px;
      right: 36px;
      height: 0;
      overflow: visible;
      .owl-prev, .owl-next {
        width: 28px;
        height: 116px;
        font-size: 0;
        background-image: url(../images/owl-nav.png);
      }
      .owl-prev {
        background-position: 0 0;
        float: left;
      }
      .owl-next {
        background-position: -28px 0;
        float: right;
      }
    }
    .slide-box {
      background-color: rgba(255, 255, 255, 0.7);
      padding: 10px 15px;
      margin-top: 220px;
      z-index: 1000;
    }
    .slide-big {
      display: block;
      font-weight: 800;
      color: @themeColor1;
      font-size: 45px;
      margin-bottom: 10px;
    }
    .slide-small {
      display: block;
      color: #fff;
      font-size: 20px;
      font-weight: 800;
    }
    .slide-btn {
        background: #bf1515; /* Old browsers */
        background: -moz-linear-gradient(top,  #bf1515 0%, #7c0404 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top,  #bf1515 0%,#7c0404 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom,  #bf1515 0%,#7c0404 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#bf1515', endColorstr='#7c0404',GradientType=0 ); /* IE6-9 */
        border: 0;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0;
        color: @whiteColor;
        margin: 20px 0;
        padding: 12px 20px;
        font-size: 22px;
        font-weight: 800;
        -webkit-box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.4);
        -moz-box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.4);
        box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.4);

        &:after, &::after {
            content: " »";
        }

        &:hover, &:focus, &:active {
            background: #bf1515; /* Old browsers */
            background: -moz-linear-gradient(top,  #bf1515 0%, #7c0404 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(top,  #bf1515 0%,#7c0404 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to bottom,  #bf1515 0%,#7c0404 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#bf1515', endColorstr='#7c0404',GradientType=0 ); /* IE6-9 */
            color: @whiteColor;
            text-decoration: underline;
            &:after, &::after {
                text-decoration: none;
            }
        }
    }
    // Extra small devices
    @media (max-width: @screen-xs-max) {
      .slide {
        padding-top: 0;
      }
    }
    // Small devices and Extra small devices
    @media (max-width: @screen-sm-max) {
      .owl-nav {
        display: none;
      }
    }
}

.product-view {
    position: relative;
    background-color: @whiteColor;
    margin: 10px auto 60px auto;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.4);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.4);
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.4);  
    max-width: 480px; 
    // Small devices
    @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
        display: table;
        width: 100%;
    }
    // Large and medium devices
    @media (min-width: @screen-md-min) {
        width: auto;
        margin-bottom: 40px;
    }
    &__image {
        padding: 0;
        img {
            margin: 0 auto;
        }
        // Small devices
        @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
            display: table-cell;
            vertical-align: middle;
            width: auto;
        }
    }
    &__desc {
        text-align: center;
        font-size: 20px;
        font-weight: 800;
        text-transform: uppercase;
        // Small devices
        @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
            display: table-cell;
            vertical-align: middle;
            float: none;
        }
    }   
    &__btn, &__btn:active {
        display: block;
        position: absolute;
        bottom: 22px;
        left: 50%;
        z-index: 2;
        width: 170px;
        padding: 12px 0px;
        height: 47px;
        margin: 0 0 -45px -85px;
        border: 0;
        color: @whiteColor;
        text-transform: uppercase;
        font-size: 18px;
        font-weight: 800;
        -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.4);
        -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.4);
        box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.4);
        background: #fc0000;
        background: -moz-linear-gradient(top, #fc0000 0%, #a20b0b 100%);
        background: -webkit-linear-gradient(top, #fc0000 0%, #a20b0b 100%);
        background: linear-gradient(to bottom, #fc0000 0%, #a20b0b 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fc0000', endColorstr='#a20b0b', GradientType=0);
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0;
        &:hover, &:active, &:focus {
            text-decoration: underline;
            color: @whiteColor;
        }
    }   
}


    .nav {
        &-pills {
            width: 100%;
            z-index: 998;
            // Large devices
            @media (min-width: @screen-lg-min) {
                width: 230px;
                margin-right: 25px;
            }
            > li {
                > a {
                    background-color: #f2f2f2;
                    border-top: 1px solid #e3e3e3;
                    color: @blackColor;
                    font-size: 17px;
                    font-weight: 800;
                    padding: 12px 25px 12px 12px;
                    -webkit-border-radius: 0;
                    -moz-border-radius: 0;
                    border-radius: 0;
                    &:after, &::after {
                        position: absolute;
                        right: 15px;
                        top: 50%;
                        margin-top: -8px;
                        content: "";
                        background: url(../images/nav-arrows.png);
                        width: 4px;
                        height: 16px;
                        background-position: -4px 0;
                    }
                } 
                &:hover, &:focus {
                    > a {
                        color: @whiteColor;
                        background-color: @themeColor1;
                        border-top: 1px solid lighten(@themeColor1, 20%);
                        &:after, &::after {
                            background-position: 0 0;
                        }
                    }
                }
                > ul {
                    @media (min-width: @screen-lg-min) {
                        max-height: 0;
                        -webkit-transition: max-height 300ms ease-out;
                        -moz-transition: max-height 300ms ease-out;
                        -ms-transition: max-height 300ms ease-out;
                        -o-transition: max-height 300ms ease-out;
                        transition: max-height 300ms ease-out;
                        -webkit-transition-delay: 1s;
                        -moz-transition-delay: 1s;
                        -o-transition-delay: 1s;
                        transition-delay: 1s;
                    }
                    list-style-type: none;
                    padding-left: 0;
                    overflow: hidden;
                    li {
                        > a {
                            position: relative;
                            display: block;
                            text-transform: none;
                            background-color: #f2f2f2;
                            border-top: 1px solid #b3b3b3;
                            color: @blackColor;
                            font-size: 16px;
                            font-weight: 500;
                            padding: 12px 25px 12px;
                            -webkit-border-radius: 0;
                            -moz-border-radius: 0;
                            border-radius: 0;
                            @media (max-width: @screen-md-max) {
                                &:hover, &:focus {
                                    border-color: @blackColor !important;
                                    background-color: @blackColor;
                                    color: @whiteColor;
                                    text-decoration: none;
                                    &:after, &::after {        
                                        background-position: 0 0 !important;
                                    }  
                                }
                            }
                        }
                        &:hover, &:focus {
                            > a {
                                @media (min-width: @screen-lg-min) {
                                    border-color: @blackColor !important;
                                    background-color: @blackColor;
                                    color: @whiteColor;
                                    text-decoration: none;
                                    &:after, &::after {        
                                        background-position: 0 0;
                                    }
                                }
                            }   
                        }
                        ul {
                            list-style-type: none;
                            padding-left: 0;
                            @media (min-width: @screen-lg-min) {
                                width: 0;
                                max-width: 0;
                                -webkit-transition: max-width 200ms ease-out, width 200ms ease-out;
                                -moz-transition: max-width 200ms ease-out, width 200ms ease-out;
                                -ms-transition: max-width 200ms ease-out, width 200ms ease-out;
                                -o-transition: max-width 200ms ease-out, width 200ms ease-out;
                                transition: max-width 200ms ease-out, width 200ms ease-out;
                                    -webkit-transition-delay: 0.3s;
                                    -moz-transition-delay: 0.3s;
                                    -o-transition-delay: 0.3s;
                                    transition-delay: 0.3s;
                                position: absolute;
                                left: 100%;
                                margin-top: -47px;
                            }
                            width: 100%;
                            > li {
                                > a {
                                    position: relative;
                                    display: table;
                                    text-transform: none;
                                    background-color: #d2d2d2;
                                    border-top: 1px solid #b3b3b3;
                                    border-width: 1px 0 0 0;
                                    color: @blackColor;
                                    font-weight: 500;
                                    -webkit-border-radius: 0;
                                    -moz-border-radius: 0;
                                    border-radius: 0;
                                    height: 47px;
                                    width: 100%;
                                    @media (min-width: @screen-lg-min) {
                                        padding: 0;
                                        font-size: 0;
                                        -webkit-transition: font-size 200ms ease-out, padding 200ms ease-out, border-width 200ms ease-out;
                                        -moz-transition: font-size 200ms ease-out, padding 200ms ease-out, border-width 200ms ease-out;
                                        -ms-transition: font-size 200ms ease-out, padding 200ms ease-out, border-width 200ms ease-out;
                                        -o-transition: font-size 200ms ease-out, padding 200ms ease-out, border-width 200ms ease-out;
                                        transition: font-size 200ms ease-out, padding 200ms ease-out, border-width 200ms ease-out;
                                        -webkit-transition-delay: 0.3s;
                                        -moz-transition-delay: 0.3s;
                                        -o-transition-delay: 0.3s;
                                        transition-delay: 0.3s;
                                    }

                                    &:after, &::after {
                                        position: absolute;
                                        right: 15px;
                                        top: 50%;
                                        margin-top: -8px;
                                        content: "";
                                        background: url(../images/nav-arrows.png);
                                        height: 16px;
                                        background-position: -4px 0;
                                        @media (min-width: @screen-lg-min) {
                                            width: 0;
                                            max-width: 0;
                                            -webkit-transition-delay: 0.3s;
                                            -moz-transition-delay: 0.3s;
                                            -o-transition-delay: 0.3s;
                                            transition-delay: 0.3s;
                                        }
                                    }
                                    &:hover, &:focus {
                                        border-color: @blackColor !important;
                                        background-color: @blackColor;
                                        color: @whiteColor !important;
                                        text-decoration: none;
                                        font-size: 16px;
                                        -webkit-transition: font-size 200ms ease-in, padding 200ms ease-in, border-width 200ms ease-in;
                                        -moz-transition: font-size 200ms ease-in, padding 200ms ease-in, border-width 200ms ease-in;
                                        -ms-transition: font-size 200ms ease-in, padding 200ms ease-in, border-width 200ms ease-in;
                                        -o-transition: font-size 200ms ease-in, padding 200ms ease-in, border-width 200ms ease-in;
                                        transition: font-size 200ms ease-in, padding 200ms ease-in, border-width 200ms ease-in;
                                        &:after, &::after {        
                                            background-position: 0 0;
                                        }
                                    }
                                }
                                ul {
                                    bottom: 0;
                                }
                            }
                        }
                        &.sub {
                            > a {
                                &:after, &::after {
                                    position: absolute;
                                    right: 15px;
                                    top: 50%;
                                    margin-top: -8px;
                                    content: "";
                                    background: url(../images/nav-arrows.png);
                                    width: 4px;
                                    height: 16px;
                                    background-position: -4px 0;
                                }
                            }
                            &:hover, &:focus {
                                > a {
                                    -webkit-transition: background-color 0s ease-out;
                                    -moz-transition: background-color 0s ease-out;
                                    -ms-transition: background-color 0s ease-out;
                                    -o-transition: background-color 0s ease-out;
                                    transition: background-color 0s ease-out;
                                    &:after, &::after { 
                                        @media (min-width: @screen-lg-min) {
                                            background-position: 0 0; 
                                            -webkit-transition: background-position 0s ease-out;
                                            -moz-transition: background-position 0s ease-out;
                                            -ms-transition: background-position 0s ease-out;
                                            -o-transition: background-position 0s ease-out;
                                            transition: background-position 0s ease-out;
                                        }
                                    }
                                }
                                > ul {
                                    width: 100%;
                                    max-width: 5000px;
                                    -webkit-transition: max-width 200ms ease-in, width 200ms ease-in;
                                    -moz-transition: max-width 200ms ease-in, width 200ms ease-in;
                                    -ms-transition: max-width 200ms ease-in, width 200ms ease-in;
                                    -o-transition: max-width 200ms ease-in, width 200ms ease-in;
                                    transition: max-width 200ms ease-in, width 200ms ease-in;
                                    -webkit-transition-delay: 0.2s;
                                    -moz-transition-delay: 0.2s;
                                    -o-transition-delay: 0.2s;
                                    transition-delay: 0.2s;
                                    > li {
                                        > a {
                                            font-size: 16px;
                                            padding: 12px 25px 12px 12px;
                                            border: 1px solid #b3b3b3;
                                            border-width: 1px 0 0 0;
                                            @media (min-width: @screen-lg-min) {
                                                border-width: 1px 1px 0 0;
                                                padding-left: 12px;
                                            }
                                            -webkit-transition: font-size 200ms ease-in, padding 200ms ease-in, border-width 200ms ease-in;
                                            -moz-transition: font-size 200ms ease-in, padding 200ms ease-in, border-width 200ms ease-in;
                                            -ms-transition: font-size 200ms ease-in, padding 200ms ease-in, border-width 200ms ease-in;
                                            -o-transition: font-size 200ms ease-in, padding 200ms ease-in, border-width 200ms ease-in;
                                            transition: font-size 200ms ease-in, padding 200ms ease-in, border-width 200ms ease-in;
                                            -webkit-transition-delay: 0.2s;
                                            -moz-transition-delay: 0.2s;
                                            -o-transition-delay: 0.2s;
                                            transition-delay: 0.2s;
                                        }
                                        &.sub {
                                            > a {
                                                &:after, &::after {
                                                    width: 4px;
                                                    max-width: 4px;
                                                    -webkit-transition: max-width 200ms ease-in, width 200ms ease-in;
                                                    -moz-transition: max-width 200ms ease-in, width 200ms ease-in;
                                                    -ms-transition: max-width 200ms ease-in, width 200ms ease-in;
                                                    -o-transition: max-width 200ms ease-in, width 200ms ease-in;
                                                    transition: max-width 200ms ease-in, width 200ms ease-in;
                                                    -webkit-transition-delay: 0.5s;
                                                    -moz-transition-delay: 0.2s;
                                                    -o-transition-delay: 0.2s;
                                                    transition-delay: 0.2s;
                                                }
                                            }
                                        }
                                        &:last-of-type {
                                            > a {
                                                @media (min-width: @screen-lg-min) {
                                                    border-width: 1px 1px 1px 0;
                                                }
                                            }
                                        }
                                    }
                                }                        
                            }
                        }
                    }
                }
                &.sub {
                    > a {
                        -webkit-transition-delay: 1.2s;
                        -moz-transition-delay: 1.2s;
                        -o-transition-delay: 1.2s;
                        transition-delay: 1.2s;
                        &:after, &::after {        
                            background-position: 0 -20px; 
                            width: 16px;
                            height: 4px;
                            margin-top: -2px;
                            right: 11px;
                            -webkit-transition-delay: 1.2s;
                            -moz-transition-delay: 1.2s;
                            -o-transition-delay: 1.2s;
                            transition-delay: 1.2s;
                        }
                    }
                    &:hover, &:focus {
                        > a {
                            -webkit-transition: background-color 0s ease-out;
                            -moz-transition: background-color 0s ease-out;
                            -ms-transition: background-color 0s ease-out;
                            -o-transition: background-color 0s ease-out;
                            transition: background-color 0s ease-out;
                            &:after, &::after {        
                                background-position: 0 -16px; 
                                width: 16px;
                                height: 4px;
                                margin-top: -2px;
                                right: 11px;
                                -webkit-transition: background-position 0s ease-out;
                                -moz-transition: background-position 0s ease-out;
                                -ms-transition: background-position 0s ease-out;
                                -o-transition: background-position 0s ease-out;
                                transition: background-position 0s ease-out;
                            }
                        }
                        > ul {
                            max-height: 5000px;
                            -webkit-transition: max-height 300ms ease-in;
                            -moz-transition: max-height 300ms ease-in;
                            -ms-transition: max-height 300ms ease-in;
                            -o-transition: max-height 300ms ease-in;
                            transition: max-height 300ms ease-in;
                            -webkit-transition-delay: 0.2s;
                            -moz-transition-delay: 0.2s;
                            -o-transition-delay: 0.2s;
                            transition-delay: 0.2s;
                        }                        
                    }
                }
                &.active {
                    > a, > a:hover, > a:focus {
                        background-color: @themeColor1;
                        color: @whiteColor;
                        border-top: 1px solid lighten(@themeColor1, 20%);
                        &:after, &::after {        
                            background-position: 0 0;
                        }
                    }
                    &.sub > a {
                        &:after, &::after {        
                            background-position: 0 -16px; 
                            width: 16px;
                            height: 4px;
                            margin-top: -2px;
                            right: 11px;
                        }
                        ~ ul {
                            max-height: 5000px;
                            -webkit-transition: max-height 300ms ease-in;
                            -moz-transition: max-height 300ms ease-in;
                            -ms-transition: max-height 300ms ease-in;
                            -o-transition: max-height 300ms ease-in;
                            transition: max-height 300ms ease-in;
                        }
                    }
                }  
                &:last-of-type {
                    border-bottom: 1px solid #e3e3e3;
                }  
                @media (max-width: @screen-md-max) {
                    .sub, &:hover .sub {
                        li > a {
                            padding-left: 36px !important;
                        }    
                        .sub, ul:hover .sub {
                            li > a {
                                padding-left: 48px !important;
                            }     
                        } 
                    }

                    .sub {
                        > a {
                            border-bottom: 1px solid #868686 !important;
                        }
                        > ul > li {
                            > a {
                                background-color: #afafaf;
                                border-color: #868686 !important;
                                &:hover {
                                        background-color: @blackColor;
                                        border-color: @blackColor !important; 
                                }
                            } 
                            &:first-of-type > a {
                                border-top: 0 !important;
                            }  
                        } 
                        .sub {
                            > a {
                                border-bottom: 1px solid #616161 !important;
                            }
                            > ul > li {
                                > a {
                                    background-color: #868585;
                                    border-color: #616161 !important;
                                    &:hover {
                                        background-color: @blackColor;
                                        border-color: @blackColor !important;    
                                    }
                                }  
                                &:first-of-type > a {
                                    border-top: 0 !important;
                                }  
                            }
       
                        } 
                    }   
                }     
            }

            button {
                background: #fc0000;
                background: -moz-linear-gradient(top, #fc0000 0%, #a20b0b 100%);
                background: -webkit-linear-gradient(top, #fc0000 0%, #a20b0b 100%);
                background: linear-gradient(to bottom, #fc0000 0%, #a20b0b 100%);
                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fc0000', endColorstr='#a20b0b', GradientType=0);
                border: 0; 
                color: #ffffff;
            }
        }  

        &-stacked {
            padding-right: 0;
            > li + li {
                margin-top: 0;
            }
        }
    }

        .product {
            padding-bottom: 5px;
            margin-bottom: 30px;
            border-bottom: 1px solid #bebebe;
                text-align: center;
                // Large and Medium devices
                @media (min-width: @screen-md-min) {
                    text-align: left;
                }    
            &__image {
                picture {
                    display: inline-block;
                }
                img {
                    max-width: 100%;
                    margin: 0 auto;
                    -webkit-box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.4);
                    -moz-box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.4);
                    box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.4);                
                }                       
                    // Large and Medium devices
                    @media (min-width: @screen-md-min) {
                        padding-right: 20px;
                    }     
            }
            &__title {
                font-size: 25px;
                font-weight: 800;
                color: @themeColor1;
                margin-bottom: 15px;
                margin-top: 30px;
                text-align: center;
                // Large and Medium devices
                @media (min-width: @screen-md-min) {
                    margin-top: 0;
                    text-align: left;
                }               
            }
            &__desc {
                font-size: 15px;
                font-weight: 500;
                color: #3e3e3e;
                margin-bottom: 20px;
                text-align: left;
                // ExtraSmall and Small devices
                @media (max-width: @screen-sm-max) {
                    text-align: center;
                    max-width: 550px;
                    margin: 0 auto 20px auto;
                }   
            }
            &__btn, &__btn:active {
                border: 0;
                color: @whiteColor;
                margin-top: 10px;
                margin-bottom: 25px;
                padding: 10px 15px;
                font-size: 18.02px;
                -webkit-box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.4);
                -moz-box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.4);
                box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.4);
                background: #fc0000;
                background: -moz-linear-gradient(top, #fc0000 0%, #a20b0b 100%);
                background: -webkit-linear-gradient(top, #fc0000 0%, #a20b0b 100%);
                background: linear-gradient(to bottom, #fc0000 0%, #a20b0b 100%);
                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fc0000', endColorstr='#a20b0b', GradientType=0);
                -webkit-border-radius: 0;
                -moz-border-radius: 0;
                border-radius: 0;

                span:after, span::after {
                    content: " »";
                    vertical-align: text-bottom;
                }

                &:hover, &:focus {
                    color: @whiteColor;
                    text-decoration: none;
                    span {
                        border-bottom: 1px solid @whiteColor;
                    }
                }
            }
        }