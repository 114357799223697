.header {
    
}

.navbar {
	&-style {
		background-color: @themeColor2;
		border-radius: 0;
		border: 0;
		margin-bottom: 0;
		.nav > li > a {
			color: @whiteColor;
			background-color: transparent;
			border-bottom: 5px solid transparent;
			padding-left: 13px;
			padding-right: 13px;
			&:hover, &:focus, &.active {
				border-bottom-color: @themeColor1;
			}
		}
		@media (max-width: @grid-float-breakpoint-max) {
			.nav > li {
				> a {border-bottom: 0;}
				&:hover a, &:focus a, &.active a {
					background-color: @themeColor1;
				}
			}
		}
	}
	&-nav {
		margin-top: 38px;
	}
	&-brand {
		padding: 11px 0 9px 0;
		height: auto;
	}
	&-toggle {
		margin-top: 29.5px;
		border-color: @whiteColor;
		color: @whiteColor;

		.icon-bar {
			background-color: @whiteColor;
		}
	}
}