//
// Variables
// --------------------------------------------------
@contactIconColor:                  @themeColor1;
@contactFooterBorderColor:          @themeColor1;
@contactButtonTextColor:            @whiteColor;
@contactButtonBgColor:              @themeColor1;
@contactButtonBorderColor:          @themeColor2;
@contactButtonTextColorHover:       @whiteColor;
@contactButtonBgColorHover:         @themeColor2;
@contactButtonBorderColorHover:     @themeColor1;

//
// Essential classes
//---------------------------------------------------
.tile-padding {
    padding-right: 10px;
    padding-left: 10px;
}

//
// Contacts
//---------------------------------------------------
.contact-item {
    &__title {
        font-size: 20px;
        font-weight: 500;
        margin-top: 30px;
    }
    &__text {
        font-size: 0;
        > ul {
            padding: 0;
            vertical-align: top;
            > li {
                font-size: 15px;
                display: block;
                padding: 0 30px 0px 40px;
                margin-bottom: 10px;
                position:relative;
                min-height:25px;
                &:before, &::before {
                    font: normal normal normal 14px/1 FontAwesome;
                    font-size: inherit;
                    text-rendering: auto;
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                    top: 50%;
                    transform: translate(0, -50%);
                    -webkit-transform: translate(0, -50%);
                    -moz-transform: translate(0, -50%);
                    -o-transform: translate(0, -50%);
                    -ms-transform: translate(0, -50%);
                    font-size: 22px;
                    content: "";
                    position:absolute;
                    left:0;        
                    color: @contactIconColor;               
                }
                &.map-marker {&:before, &::before{content: "\f041";}}
                &.earphone {&:before, &::before{content: "\f095";margin-top: -1px;}}
                &.envelope {color: @themeColor1;&:before, &::before{content: "\f003";margin-top: -3px;}}
            }
        }
    }

    .form-control {
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0;
        background-color: #fff;
        border: 1px solid #9e9d9d;
        height: 48px;
        font-size: 15px;
        padding-left: 15px;
        &::-webkit-input-placeholder {
           color: #010101;
        }

        &:-moz-placeholder { /* Firefox 18- */
           color: #010101;  
        }

        &::-moz-placeholder {  /* Firefox 19+ */
           color: #010101;  
        }

        &:-ms-input-placeholder {  
           color: #010101;  
        }
    }
    textarea.form-control {
        height: auto;
        padding-top: 10px;
    }
    &__btn, &__btn:active {
        background: #fc0000;
        background: -moz-linear-gradient(top, #fc0000 0%, #a20b0b 100%);
        background: -webkit-linear-gradient(top, #fc0000 0%, #a20b0b 100%);
        background: linear-gradient(to bottom, #fc0000 0%, #a20b0b 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fc0000', endColorstr='#a20b0b', GradientType=0);
        border: 0;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0;
        color: @whiteColor;
        width: 100%;
        padding: 14px 0;
        font-size: 14.02px;
        font-weight: 800;
        -webkit-box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.4);
        -moz-box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.4);
        box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.4);
        // Large, medium and small devices
        @media (min-width: @screen-sm-min) {
            float: right;
            font-size: 18.02px;
            padding: 11px 0;
            span:after, span::after {
                content: " »";
                vertical-align: text-bottom;
            }
        }



        &:hover, &:focus {
            color: @whiteColor;
            text-decoration: none;
            outline: 0 auto -webkit-focus-ring-color;
            outline-offset: 0;
            span {
                border-bottom: 1px solid @whiteColor;
            }
        }
    }
}

.footer {
    .form-control {
        background-color: #202020;
        border: 1px solid #9e9d9d;
        &::-webkit-input-placeholder {
           color: @whiteColor;
        }

        &:-moz-placeholder { /* Firefox 18- */
           color: @whiteColor;  
        }

        &::-moz-placeholder {  /* Firefox 19+ */
           color: @whiteColor;  
        }

        &:-ms-input-placeholder {  
           color: @whiteColor;  
        }
    }
}