//
// Variables
// --------------------------------------------------
@gallery-titleColor:             @whiteColor;
@gallery-titleColorHover:        @whiteColor;
@gallery-overlayTextColor:       @whiteColor;
@gallery-overlayTextColorHover:  @themeColor1;

//
// Essential classes
//---------------------------------------------------
.gallery-padding {
    padding-right: 15px;
    padding-left: 15px;
}

//
// Gallery
//---------------------------------------------------
.gallery-pic {
    text-align: center;
    position: relative;
    display: inline-block;
    margin-top: 15px;
    margin-bottom: 15px;
    -webkit-box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.75);
    overflow: hidden;

    &:hover, &--active {
        .gallery-pic__image:after, .gallery-pic__image::after {
            background-color: rgba(0, 0, 0, 0.6);
        }
        .gallery-pic__overlay {               
            top: 55%;
            opacity: 1;
        }
        .gallery-pic__title {
            color: @gallery-titleColorHover;
        }
    }

    &__image {
        &:after, &::after {
            content: "";
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0);
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            -webkit-transition: all 0.3s;
            -o-transition: all 0.3s;
            transition: all 0.3s;
        }
    }
    &__overlay {
        display: inline-block;
        position: absolute;
        top: 150%;
        left: 0;
        right: 0;
        transform: translate(0, -50%);
        -webkit-transform: translate(0, -50%);
        -moz-transform: translate(0, -50%);
        -o-transform: translate(0, -50%);
        -ms-transform: translate(0, -50%);
        -webkit-transition: all 0.3s;
        -o-transition: all 0.3s;
        transition: all 0.3s;
        opacity: 0;
        a {
            font-size: 32px;
            color: @gallery-overlayTextColor;
            padding: 6px;

            // Medium devices
            @media (min-width: @screen-xs-min) and (max-width: @screen-xs-max) {
                font-size: 64px;
                padding: 12px;
            }

            &:hover {
                color: @gallery-overlayTextColorHover;
                text-decoration: none;
            }
        }
    }  
    &__title {
        position: absolute;
        bottom: 0;
        width: 100%;
        color: @gallery-titleColor;
        background-color: rgba(0, 0, 0, 0.5);
        padding: 20px 10px;
        font-size: 20px;
        line-height: normal;

        // Medium devices
        @media (min-width: @screen-xs-min) and (max-width: @screen-xs-max) {
            font-size: 24px;
            padding: 24px 16px;
        }
    }  
}