.section {
	padding-top: 30px;
	padding-bottom: 30px;
    + .section {
    	&:extend(.section);
    	&:extend(.shadow-inset-t-b);
    	background-color: #f3f3f3;
    }
    &--slider {
		height: 600px;
    }
}